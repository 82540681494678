<template>
	<div class="cause-container">
		<div class="cause-container__content-section">
			<div class="cause-container__content" :class="isLoading && 'cause-container__content--loading'">
				<div class="cause-container__title-and-options">
					<GlobalTitleComponent />
				</div>
				<div v-if="currentState?.type === StepType.WAS_PACKAGE_EMPTY" class="cause-container__options">
					<GlobalOptionComponent
						:class="{ 'global-option-select--selected': wasPackageOpen === 'Tak' }"
						:select="{ selectName: 'Tak', icon: IconKeys.PACKAGE_DAMAGED }"
						@click="wasPackageOpen = 'Tak'" />
					<GlobalOptionComponent
						:class="{ 'global-option-select--selected': wasPackageOpen === 'Nie' }"
						:select="{ selectName: 'Nie', icon: IconKeys.PACKAGE }"
						@click="wasPackageOpen = 'Nie'" />
				</div>
				<div v-if="currentState?.type === StepType.STICKER_ON_PACKAGE" class="cause-container__options">
					<GlobalOptionComponent
						:class="{ 'global-option-select--selected': wasStickerOnPackage === 'Tak' }"
						:select="{ selectName: 'Tak', icon: IconKeys.CIRCLE_YES }"
						@click="wasStickerOnPackage = 'Tak'" />
					<GlobalOptionComponent
						:class="{ 'global-option-select--selected': wasStickerOnPackage === 'Nie' }"
						:select="{ selectName: 'Nie', icon: IconKeys.CIRCLE_X }"
						@click="wasStickerOnPackage = 'Nie'" />
					<GlobalOptionComponent
						:class="{ 'global-option-select--selected': wasStickerOnPackage === 'Nie wiem' }"
						:select="{ selectName: 'Nie wiem', icon: IconKeys.CIRCLE_QUESTION }"
						@click="wasStickerOnPackage = 'Nie wiem'" />
				</div>
				<div class="cause-container__buttons">
					<ButtonSecondary
						:text="'Poprzedni krok'"
						@click="
							() => {
								getPreviousStep(), toggleLoader()
							}
						"></ButtonSecondary>
					<ButtonPrimary
						:text="'Dalej'"
						:disabled="!wasPackageOpen"
						@click="
							getNextStep(currentState?.type === StepType.WAS_PACKAGE_EMPTY ? wasPackageOpen : wasStickerOnPackage)
						"></ButtonPrimary>
					<!-- <v-btn class="btn cause-container__back-button" variant="outlined" rounded @click="getNextStep(currentState?.type === StepType.WAS_PACKAGE_EMPTY ? wasPackageOpen : wasStickerOnPackage)">Poprzedni krok</v-btn>
					<v-btn
						class="btn btn--primary"
						variant="outlined"
						rounded
						:class="{ 'cause-container__next-button--disabled': wasPackageOpen === undefined }"
						@click="getNextStep(currentState?.type === StepType.WAS_PACKAGE_EMPTY ? wasPackageOpen : wasStickerOnPackage)"
						>Dalej</v-btn
					> -->
				</div>
			</div>
		</div>
		<div class="cause-container__image-section">
			<img src="~/assets/img/main-picture.webp" alt="viking" class="cause-container__image" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { adjacencyList } from '~/data/adjacencyListGraph'
import { IconKeys } from '~/data/iconKeys'
import { INode } from '~/types/nodeTypes'

const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, getLastValueFromHistory, getPreviousStep } = useNavigationService()
// const { validateForm } = useFormValidation()
// const form = ref()

const wasPackageOpen = ref()
const wasStickerOnPackage = ref()
const isLoading = ref(false)
const router = useRouter()

const getNextStep = (select: string) => {
	if (!currentState.value?.key) return
	// if (!(await validateForm(form.value))) return

	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], select)
	router.push({ hash: `#${currentState.value.key}` })
}

const toggleLoader = () => {
	isLoading.value = true
	setTimeout(() => (isLoading.value = false), 300)
	const result = getLastValueFromHistory()
	if (result) wasPackageOpen.value = result as string
	if (result) wasStickerOnPackage.value = result as string
}

// const rules = ref([
// 	(value: string) => {
// 		if (value) return true
// 		return 'Musisz zaznaczyc jedna z opcji'
// 	},
// ])

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) wasPackageOpen.value = result
	if (result) wasStickerOnPackage.value = result
})
</script>

<style lang="scss">
@import './PackageEmptyComponent.scss';
</style>
