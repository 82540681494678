<template>
	<div>
		<v-form ref="form">
			<v-radio-group v-model="complaintMethod" :rules="rules">
				<v-radio label="Radio 1" value="1"></v-radio>
				<v-radio label="Radio 2" value="2"></v-radio>
				<v-radio label="Radio 3" value="3"></v-radio>
			</v-radio-group>
		</v-form>

		<v-btn @click="getNextStep(complaintMethod)">Next</v-btn>
	</div>
</template>

<script setup lang="ts">
import { adjacencyList } from '~/data/adjacencyListGraph'
import { INode } from '~/types/nodeTypes'

const complaintMethod = ref()
const { changeSiteState, getLastValueFromHistory } = useNavigationService()
const { validateForm } = useFormValidation()

const currentState = inject('currentState') as Ref<INode>
const form = ref()
const router = useRouter()

const getNextStep = async (select: string) => {
	if (!(await validateForm(form.value))) return
	if (!currentState.value?.key) return
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], select)
	router.push({ hash: `#${currentState.value.key}` })
}

const rules = ref([
	(value: string) => {
		if (value) return true
		return 'Musisz zaznaczyc jedna z opcji'
	},
])

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) complaintMethod.value = result
})
</script>

<style lang="scss">
@import './ComplaintMethodsComponent.scss';
</style>
