<template>
	<div class="cause-container">
		<div ref="el" class="cause-container__content-section">
			<div class="cause-container__content" :class="isLoading && 'cause-container__content--loading'">
				<div class="cause-container__title-and-options">
					<GlobalTitleComponent></GlobalTitleComponent>
				</div>
				<div class="cause-container__options">
					<GlobalOptionComponent
						v-for="(select, index) in selectChoices"
						:key="index"
						:class="{ 'global-option-select--selected': select.key === selectedStep }"
						:select="select"
						@click="selectedStep = select.key" />
				</div>
				<div class="cause-container__buttons" :class="currentState.key === NodeKeys.MODULE && 'cause-container__buttons--right'">
					<ButtonSecondary
						v-if="currentState.key !== NodeKeys.MODULE"
						:text="'Poprzedni krok'"
						@click="
							() => {
								getPreviousStep(), toggleLoader()
							}
						"></ButtonSecondary>
					<ButtonPrimary :text="'Dalej'" :disabled="!selectedStep" @click="onSelect(selectedStep as NodeKeys)"></ButtonPrimary>
				</div>
				<StepCauseComponentContactPartial v-if="currentState.key === NodeKeys.MODULE" />
			</div>
		</div>
		<div class="cause-container__image-section">
			<img src="~/assets/img/main-picture.webp" alt="viking" class="cause-container__image" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'
import { NodeKeys } from '~/data/keys'

const el = ref<HTMLElement | null>(null)
const { y } = useScroll(el)

const availableChoices = inject('availableChoices') as Ref<NodeKeys[]>
const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, getPreviousStep, getLastValueFromHistory, getNodes } = useNavigationService()

const selectedStep = ref<NodeKeys | null>(null)
const isLoading = ref(false)
const selectChoices = ref<INode[]>([])
const router = useRouter()

const queryMap: { [key: string]: NodeKeys } = {
	meal: NodeKeys.MEAL_PROBLEM,
	delivery: NodeKeys.DELIVERY_PROBLEM,
	package: NodeKeys.DAMAGED_PACKAGE,
}

const route = useRoute()
if (route.query.problem && currentState.value?.key === NodeKeys.MODULE) selectedStep.value = queryMap[route.query.problem as unknown as NodeKeys]

const onSelect = (select: NodeKeys) => {
	if (selectedStep.value) {
		changeSiteState(select, select)
		toggleLoader()
		selectedStep.value = null
		const element = document.querySelector('.cause-container__content-section') as HTMLElement
		element?.scrollTo({ top: 0, behavior: 'smooth' })
		router.push({ hash: `#${currentState.value.key}` })
	}
}

watch(
	() => availableChoices.value,
	(nodes) => {
		selectChoices.value = getNodes(nodes)
	},
	{ immediate: true }
)

const toggleLoader = () => {
	isLoading.value = true
	setTimeout(() => (isLoading.value = false), 300)
	const result = getLastValueFromHistory()
	if (result) selectedStep.value = result as NodeKeys
}

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) selectedStep.value = result as NodeKeys
	y.value = 0
})
</script>

<style lang="scss">
@import './CauseComponent.scss';
</style>
