<template>
	<div class="cause-container">
		<Transition name="modalFade">
			<GlobalBaseModal
				v-if="orderNumberModal"
				title="Gdzie znajdę numer zamowienia?"
				desc="Numer zamówienia rozpoczyna się znakiem # i składa się z 6 lub 7 cyfr. Znajdziesz go w potwierdzeniu mailowym po złożeniu zamówienia lub w swoim panelu klienta."
				button="OK"
				@baseModalOpen="getOrderNumberModal"></GlobalBaseModal
		></Transition>
		<div class="cause-container__content-section">
			<div class="cause-container__content">
				<div class="cause-container__title-and-options">
					<GlobalTitleComponent></GlobalTitleComponent>
				</div>
				<div class="cause-container__options">
					<v-form class="cause-container__inputs" @submit.prevent="handleEnter">
						<div
							class="cause-container__input-container"
							:class="{ 'cause-container__input-container--success': email.length > 0 && isEmailValid }">
							<v-text-field
								v-model="email"
								:error="emailErrorMessage !== emailErrors.valid"
								:error-messages="emailErrorMessage"
								single-line
								rounded
								enterkeyhint="done"
								density="comfortable"
								label="Email"
								type="email"
								variant="solo">
								<template #append-inner>
									<div v-show="email.length > 0 && isEmailValid" class="cause-container__check-container">
										<v-icon class="cause-container__check-icon" icon="mdi-check-bold"></v-icon>
									</div>
								</template>
							</v-text-field>
						</div>
						<div
							class="cause-container__input-container"
							:class="{ 'cause-container__input-container--success': numberID.length > 0 && isNumberIDValid }">
							<v-text-field
								v-model="numberID"
								:error="numberErrorMessage !== numberErrors.valid"
								:error-messages="numberErrorMessage"
								single-line
								rounded
								type="number"
								number
								density="comfortable"
								label="Numer zamówienia"
								variant="solo">
								<template #prepend-inner>
									<div class="cause-container__input-order-prepend">#</div>
								</template>
								<template #append-inner>
									<div v-show="numberID.length > 0 && isNumberIDValid" class="cause-container__check-container">
										<v-icon class="cause-container__check-icon" icon="mdi-check-bold"></v-icon>
									</div>
								</template>
							</v-text-field>
						</div>
						<div class="cause-container__info-text" @click="orderNumberModal = true">
							<v-icon icon="mdi-information-outline" class="cause-container__info-icon" color="gray"></v-icon>
							<span class="cause-container__info-text--decoration"> Gdzie znajdę numer zamówienia? </span>
						</div>
					</v-form>
				</div>
				<div class="cause-container__buttons">
					<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
					<ButtonPrimary :text="'Dalej'" :disabled="!valid" @click="getNextStep"></ButtonPrimary>
				</div>
			</div>
		</div>
		<div class="cause-container__image-section">
			<img src="~/assets/img/main-picture.webp" alt="viking" class="cause-container__image" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'

const { changeSiteState, getLastValueFromHistory } = useNavigationService()
const { getPreviousStep } = useNavigationService()

const orderNumberModal = ref(false)
const currentState = inject('currentState') as Ref<INode>
const valid = ref(false)
const email = ref('')
const numberID = ref('')
const isEmailValid = ref(false)
const isNumberIDValid = ref(false)
const emailDebounceTimeout: any = ref(null)
const numberDebounceTimeout: any = ref(null)

const router = useRouter()

const emailErrors = {
	valid: '',
	required: 'E-mail jest wymagany',
	emailValid: 'E-mail musi być prawidłowy',
}

const numberErrors = {
	valid: '',
	required: 'Numer zamówienia jest wymagany',
	hasLetters: 'Numer zamówienia powinien zawierać tylko cyfry',
	invalidLength: 'Numer zamówienia jest nieprawidłowy (6 lub 7 cyfr)',
}

watch(
	() => router.currentRoute.value.hash,
	() => {
		if (router.currentRoute.value.hash === '#order-number-modal') {
			orderNumberModal.value = true
		} else {
			orderNumberModal.value = false
		}
	}
)

watch(
	() => orderNumberModal.value,
	() => {
		if (orderNumberModal.value) {
			router.push({ hash: '#order-number-modal' })
		} else {
			router.push({ hash: '' })
		}
	}
)

const emailErrorMessage = ref(emailErrors.valid)
const numberErrorMessage = ref(numberErrors.valid)

// email regexes
const validRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

// number regexes
const validNumberRegex = /^[0-9]{6,7}$/
const containsLettersRegex = /[a-zA-Z]/

export interface UserData {
	email: string
	numberID: string
}

onMounted(() => {
	const result = getLastValueFromHistory()
	valid.value = false
	if (result) {
		email.value = (result as UserData).email
		numberID.value = (result as UserData).numberID
		isEmailValid.value = true
		isNumberIDValid.value = true
		valid.value = true
	}
})

const getNextStep = () => {
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], { email: email.value, numberID: numberID.value })
	router.push({ hash: `#${currentState.value.key}` })
}

const handleEnter = () => {
	if (valid.value) getNextStep()
}

function getOrderNumberModal(value: boolean) {
	orderNumberModal.value = value
}

onKeyStroke('Enter', handleEnter)

const validateEmail = (value: string, immediateErrors: boolean) => {
	if (value.length === 0) {
		emailErrorMessage.value = emailErrors.required
		if (immediateErrors) isEmailValid.value = false
		return false
	} else if (!validRegex.test(value)) {
		if (immediateErrors) {
			emailErrorMessage.value = emailErrors.emailValid
			isEmailValid.value = false
		}
		return false
	} else {
		emailErrorMessage.value = emailErrors.valid
		if (immediateErrors) isEmailValid.value = true
		return true
	}
}

const validateNumber = (value: string, immediateErrors: boolean) => {
	if (value.length === 0) {
		numberErrorMessage.value = numberErrors.required
		if (immediateErrors) isNumberIDValid.value = false
		return false
	} else if (containsLettersRegex.test(value)) {
		if (immediateErrors) {
			numberErrorMessage.value = numberErrors.hasLetters
			isNumberIDValid.value = false
		}
		return false
	} else if (!validNumberRegex.test(value)) {
		if (immediateErrors) {
			numberErrorMessage.value = numberErrors.invalidLength
			isNumberIDValid.value = false
		}
		return false
	} else {
		numberErrorMessage.value = numberErrors.valid
		if (immediateErrors) isNumberIDValid.value = true
		return true
	}
}

watch(email, (value) => {
	if (validateEmail(value, false)) {
		validateEmail(value, true)
		clearTimeout(emailDebounceTimeout.value)
	} else if (value.length > 0 && isEmailValid.value) {
		validateEmail(value, true)
	} else if (value.length === 0 && isEmailValid.value) {
		emailErrorMessage.value = emailErrors.required
		isEmailValid.value = false
	} else {
		clearTimeout(emailDebounceTimeout.value)
		emailDebounceTimeout.value = setTimeout(() => {
			validateEmail(value, true)
		}, 700)
	}
	if (isNumberIDValid.value && isEmailValid.value) valid.value = true
	else valid.value = false
})

watch(numberID, (value) => {
	if (validateNumber(value, false)) {
		validateNumber(value, true)
		clearTimeout(numberDebounceTimeout.value)
	} else if (value.length > 0 && isNumberIDValid.value) {
		validateNumber(value, true)
	} else if (value.length === 0 && isNumberIDValid.value) {
		numberErrorMessage.value = numberErrors.required
		isNumberIDValid.value = false
	} else {
		clearTimeout(numberDebounceTimeout.value)
		numberDebounceTimeout.value = setTimeout(() => {
			validateNumber(value, true)
		}, 700)
	}
	if (isNumberIDValid.value && isEmailValid.value) valid.value = true
	else valid.value = false
})
</script>

<style lang="scss">
@import './EmailComponent.scss';
</style>
