<template>
	<div class="delivery-date">
		<GlobalTitleComponent></GlobalTitleComponent>
		<div class="delivery-date__container">
			<CalendarComponent @selectedDate="updateSelectedDate" />
			<div class="delivery-date__info">
				<div class="delivery-date__info-icon-container">
					<v-icon class="delivery-date__info-icon" icon="mdi-alert-circle"></v-icon>
				</div>
				<div class="delivery-date__info-text-container">
					<p class="delivery-date__info-text">
						Reklamację możesz złożyć do 30 dni wstecz. W przypadku problemu dotyczącego starszego zamówienia, skontaktuj się z nami
						telefonicznie lub mailowo.
					</p>
				</div>
			</div>
		</div>
		<div class="delivery-date__navigation">
			<div class="delivery-date__navigation-buttons">
				<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
				<ButtonPrimary :disabled="!dateDelivery" :text="'Dalej'" @click="getNextStep(dateDelivery)"></ButtonPrimary>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'

const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, getLastValueFromHistory } = useNavigationService()
const dateDelivery = ref()
const { getPreviousStep } = useNavigationService()
const router = useRouter()
const getNextStep = (select: string) => {
	if (!currentState.value?.key) return
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], select)
	router.push({ hash: `#${currentState.value.key}` })
}

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) dateDelivery.value = result as string
})

function updateSelectedDate(value: Date) {
	dateDelivery.value = value
}
</script>

<style lang="scss">
@import './DateDeliveryComponent.scss';
</style>
