<template>
	<div class="summary-left-section">
		<div v-if="reason" class="summary-left-section__problem">{{ reason }}</div>
		<div v-if="reason" class="summary-left-section__subproblem">{{ subreason }}</div>
		<div class="summary-left-section__calendars-container">
			<div v-if="deliveryDate" class="summary-left-section__calendar">
				<div class="summary-left-section__icon-and-text">
					<v-icon class="summary-left-section__calendar-icon" icon="mdi-clock-time-four-outline"></v-icon>
					<div class="summary-left-section__calendar-text summary-left-section__calendar-text--tight">Dzień dostawy</div>
				</div>
				<div class="summary-left-section__calendar-date">{{ deliveryDate }}</div>
			</div>
			<div class="summary-left-section__calendar">
				<div class="summary-left-section__icon-and-text">
					<v-icon class="summary-left-section__calendar-icon" icon="mdi-calendar-range-outline"></v-icon>
					<div class="summary-left-section__calendar-text">Dzień zgłoszenia reklamacji</div>
				</div>
				<div class="summary-left-section__calendar-date">{{ todayDate }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const deliveryDate = ref()
const todayDate = ref()
const reason = ref()
const subreason = ref()
const { getDeliveryDateFromHistory, getReasonFromHistory, convertKeyToName } = useNavigationService()

onMounted(() => {
	const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' }
	deliveryDate.value = (getDeliveryDateFromHistory() as Date).toLocaleDateString('pl-PL', options)
	todayDate.value = new Date().toLocaleDateString('pl-PL', options)
	const reasons = getReasonFromHistory()
	if (reasons) {
		reason.value = convertKeyToName(reasons[reasons.length - 2].value)
		subreason.value = convertKeyToName(reasons[reasons.length - 1].value)
	}
})
</script>

<style lang="scss">
@import './LeftSectionPartial.scss';
</style>
