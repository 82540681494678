<template>
	<div class="diet-picker">
		<div v-if="fetchedVikingDiets.length > 0" class="diet-picker__container">
			<GlobalTitleComponent></GlobalTitleComponent>
			<div class="diet-picker__search-bar">
				<v-text-field v-model="dietSearch" single-line rounded label="Wpisz nazwę diety" hide-details="auto" variant="solo">
					<template #prepend-inner>
						<v-icon class="diet-picker__search-bar__icon" icon="mdi-magnify" />
					</template>
				</v-text-field>
			</div>
			<div class="diet-picker__spacer">
				<v-divider></v-divider>
				<div class="diet-picker__spacer-text">lub odszukaj na liście</div>
				<v-divider></v-divider>
			</div>
			<div ref="dietCard" class="diet-picker__diets-container">
				<GlobalFoodComponent
					v-for="diet in searchResult"
					:key="diet.name"
					:food="diet"
					:selected="isSelected(diet.name)"
					:multiple-options="isMultiple"
					@click="selectDiet(diet.name)"></GlobalFoodComponent>
			</div>
		</div>
		<div class="diet-picker__navigation">
			<div class="diet-picker__navigation-buttons">
				<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
				<ButtonPrimary :text="'Dalej'" :disabled="!isFormValid" @click="getNextStep"></ButtonPrimary>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import autoAnimate from '@formkit/auto-animate'
import { adjacencyList } from '~/data/adjacencyListGraph'
import { INode } from '~/types/nodeTypes'
import { NodeKeys } from '~/data/keys'
import { IFood } from '~/components/Global/FoodComponent/FoodComponent.vue'

const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, checkRequirementsForNodes, getLastValueFromHistory } = useNavigationService()
const { getPreviousStep } = useNavigationService()
const dietCard = ref()

const siteStore = useSiteStore()
const fetchedVikingDiets = ref<IFood[]>([])
const searchResult = ref<IFood[]>([])
const selectedDiet = ref<string>()
const selectedDiets = ref<string[]>([])
const dietSearch = ref<string>('')
const isFormValid = ref<boolean>(false)
const router = useRouter()
const animationLoaded = ref(false)
const isMultiple = ref(currentState.value.type === StepType.DIET_PICKER_MULTIPLE)

const isSelected = (diet: string) => {
	if (isMultiple.value) return selectedDiets.value.includes(diet)
	return selectedDiet.value === diet
}
watch(
	() => dietSearch.value,
	(newValue) => {
		if (!animationLoaded.value) {
			autoAnimate(dietCard.value)
			animationLoaded.value = true
		}
		searchResult.value = fetchedVikingDiets.value.filter((diet) => diet.name.toLowerCase().includes(newValue.toLowerCase()))
	}
)

watch(
	() => selectedDiet.value,
	() => {
		isFormValid.value = validateComponent()
	}
)

const selectDiet = (diet: string) => {
	if (isMultiple.value) {
		const index = selectedDiets.value.indexOf(diet)
		if (index > -1) selectedDiets.value.splice(index, 1)
		else selectedDiets.value.push(diet)
	} else selectedDiet.value = diet
	isFormValid.value = validateComponent()
}

const validateComponent = () => {
	if (isMultiple.value) return selectedDiets.value.length > 0
	return !!selectedDiet.value
}

const getNextStep = () => {
	if (!validateComponent()) return
	if (!currentState.value?.key) return
	const availableNodeKeys: NodeKeys[] = adjacencyList[currentState.value?.key]
	const nodeKey = checkRequirementsForNodes(availableNodeKeys)
	const diets = isMultiple.value ? selectedDiets.value : selectedDiet.value
	if (nodeKey.length > 0) changeSiteState(nodeKey[0], diets as string | string[])
	router.push({ hash: `#${currentState.value.key}` })
}

onMounted(() => {
	fetchedVikingDiets.value = siteStore.getDiets
	searchResult.value = fetchedVikingDiets.value
	const result = getLastValueFromHistory()
	if (!result) return
	if (isMultiple.value) selectedDiets.value = result as string[]
	else selectedDiet.value = result as string
	validateComponent()
})
</script>

<style lang="scss">
@import './DietPickerComponent.scss';
</style>
