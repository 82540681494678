<template>
	<div class="success">
		<div class="success__container">
			<div class="success__title-and-desc">
				<GlobalTitleComponent />
				<p class="success__desc">
					Twoje zgłoszenie zostało przesłane! Nasz zespół zweryfikuje je w możliwie najkrótszym czasie. O wszystkich zmianach w Twoim
					zgłoszeniu poinformujemy Cie mailowo.
				</p>
			</div>
			<div class="success__checks-container">
				<StepSuccessfulComplaintComponentCheckPartial
					v-for="check in checks"
					:key="check.text"
					:text="check.text"
					:done="check.done"
					:last="check.last" />
			</div>
			<ButtonPrimary class="success__button" text="Wróć do ekranu głównego" @click="startOver"></ButtonPrimary>
		</div>
	</div>
</template>

<script setup lang="ts">
const siteStore = useSiteStore()

const startOver = () => {
	siteStore.clearHistory()
	siteStore.setCurrentNode(availableNodes[0])
	siteStore.addHistory(availableNodes[0], '')
}

const checks = [
	{
		text: 'Uzupełniony formularz',
		done: true,
	},
	{
		text: 'Zgłoszenie wysłane do Biura Obsługi Klienta',
		done: true,
	},
	{
		text: 'Oczekuje na weryfikację',
		done: true,
	},
	{
		text: 'Mailowa odpowiedź z Biura Obsługi Klienta',
		done: false,
		last: true,
	},
]
</script>

<style lang="scss">
@import './SuccessfulComplaintComponent.scss';
</style>
