<template>
	<div class="base-modal">
		<div ref="baseModal" class="base-modal__container">
			<div class="base-modal__text">
				<div class="base-modal__title">{{ props.title }}</div>
				<div class="base-modal__desc">{{ props.desc }}</div>
			</div>
			<slot />
			<div class="base-modal__buttons">
				<ButtonPrimary class="base-modal__primary-button" :text="props.button" @click="emit('baseModalOpen', false)"></ButtonPrimary>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const baseModal = ref(null)
const emit = defineEmits(['baseModalOpen'])
const props = defineProps({
	title: {
		type: String,
		default: 'prop',
	},
	desc: {
		type: String,
		default: 'prop',
	},
	button: {
		type: String,
		default: 'prop',
	},
})

onClickOutside(baseModal, () => {
	emit('baseModalOpen', false)
})
</script>

<style lang="scss">
@import './BaseModal.scss';
</style>
