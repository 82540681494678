<template>
	<div class="custom-calendar">
		<DatePicker
			v-model="selectedDate"
			nav-visibility="none"
			trim-weeks
			expanded
			:locale="{ masks: { weekdays: ['WW'] } }"
			borderless
			transparent
			color="yellow"
			mode="date"
			:min-page="lastMonthPageAddress"
			:max-page="todayPageAddress"
			:is-required="true"
			:attributes="attrs"
			:columns="calendarColumns"
			:rows="calendarRows"
			:min-date="minDate"
			:max-date="maxDate" />
	</div>
</template>

<script setup lang="ts">
import { DatePicker } from 'v-calendar'
import { PageAddress } from 'v-calendar/dist/types/src/utils/page'
const { getLastValueFromHistory } = useNavigationService()
const emit = defineEmits(['selectedDate'])
const selectedDate = ref<Date | null>()
const today = new Date()
const today2 = new Date()

const calendarRows = ref(1)
const calendarColumns = ref(2)

const todayPageAddress = ref<PageAddress>({
	year: today.getFullYear(),
	month: today.getMonth() + 1,
})

const lastMonthPageAddress = ref<PageAddress>({
	year: today.getFullYear(),
	month: today.getMonth(),
})

const minDate = today.setDate(today.getDate() - 29)
const maxDate = today2.setDate(today2.getDate() + 2)

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) selectedDate.value = new Date(result as string)
	window.addEventListener('resize', setCalendar)
	setCalendar()
})

onUnmounted(() => {
	window.removeEventListener('resize', setCalendar)
})

const attrs = ref([
	{
		key: 'today',
		dot: {
			style: {
				backgroundColor: 'red',
				width: '6px',
				height: '6px',
				marginTop: '-13px',
			},
		},
		dates: [new Date()],
	},
])

watch(
	() => selectedDate.value,
	() => {
		emit('selectedDate', selectedDate.value)
	}
)

const setCalendar = () => {
	if (window.innerWidth <= 768) {
		calendarRows.value = 1
		calendarColumns.value = 1

		todayPageAddress.value = {
			year: today.getFullYear(),
			month: today.getMonth() + 2,
		}
	} else {
		calendarRows.value = 1
		calendarColumns.value = 2
	}
}
</script>

<style lang="scss">
@import './CalendarComponent.scss';
</style>
