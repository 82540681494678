<template>
	<div ref="el" class="main">
		<StepEmailComponent v-if="currentState?.type === StepType.EMAIL" />
		<StepCauseComponent
			v-if="
				currentState?.type === StepType.SELECT ||
				currentState?.type === StepType.SELECT_BAD_MEAL_QUALITY ||
				currentState?.type === StepType.SELECT_WRONG_PACKAGE_CONTENT
			" />
		<StepDateDeliveryComponent v-if="currentState?.type === StepType.DATE_DELIVERY" />
		<StepDateProblemComponent v-if="currentState?.type === StepType.DATE_PROBLEM" />
		<StepMealPickerComponent v-if="currentState?.type === StepType.MEAL_PICKER" />
		<StepDietPickerComponent v-if="currentState?.type === StepType.DIET_PICKER || currentState?.type === StepType.DIET_PICKER_MULTIPLE" />
		<StepPhotoUploaderComponent v-if="currentState?.type === StepType.OPTIONAL_PHOTO || currentState?.type === StepType.REQUIRED_PHOTO" />
		<StepDescriptionComponent v-if="currentState?.type === StepType.NOTES" />
		<StepComplaintMethodsComponent v-if="currentState?.type === StepType.COMPLAINT_METHODS" />
		<StepPackageEmptyComponent v-if="currentState?.type === StepType.WAS_PACKAGE_EMPTY || currentState?.type === StepType.STICKER_ON_PACKAGE" />
		<StepSummaryComponent v-if="currentState?.type === StepType.SUMMARY" />
		<StepSuccessfulComplaintComponent v-if="currentState?.type === StepType.SUCCESS" />
	</div>
</template>

<script setup lang="ts">
import { Value, getValue, fetchAndActivate } from 'firebase/remote-config'
import { adjacencyList } from '~/data/adjacencyListGraph'
import { availableNodes } from '~/data/graph'
import { StepType, NodeKeys } from '~/data/keys'
import { INode } from '~/types/nodeTypes'

const siteStore = useSiteStore()
const currentState = ref<INode | null>()
const availableChoices = ref<NodeKeys[]>([])

const el = ref<HTMLElement | null>(null)
const { y } = useScroll(el)

provide('availableChoices', availableChoices)
provide('currentState', currentState)
const { getPreviousStep } = useNavigationService()

const startOver = () => {
	siteStore.clearHistory()
	siteStore.setCurrentNode(availableNodes[0])
	siteStore.addHistory(availableNodes[0], '')
}

onMounted(() => {
	window.onpopstate = function () {
		getPreviousStep()
	}
})

startOver()

watch(
	() => siteStore.getCurrentNode,
	(currentNode) => {
		currentState.value = currentNode
		if (currentState.value?.key) {
			availableChoices.value = adjacencyList[currentState.value.key]
		}

		y.value = 0
	},
	{ immediate: true }
)

// remote config

onMounted(() => {
	const remoteConfig = getConfig()
	fetchAndActivate(remoteConfig)
		.then(() => {
			const val: Value = getValue(remoteConfig, 'vikingDiets')
			siteStore.setDiets(JSON.parse(val.asString()))
		})
		.catch((err) => {
			console.log(err)
		})
})

definePageMeta({
	layout: 'default',
})

useHead({
	title: 'Kuchnia Vikinga - reklamacje',
	meta: [
		{ name: 'description', content: 'Wygodnie zgłoś reklamację za pomocą formularza' },
		{ name: 'msapplication-TileColor', content: '#da532c' },
		{ name: 'theme-color', content: '#ffffff' },
		{ hid: 'og:title', property: 'og:title', content: 'Wygodnie zgłoś reklamację za pomocą formularza' },
		// { hid: 'og:image', property: 'og:image', content: '/main-picture.webp' },
	],
	link: [
		{ rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
		{ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
		{ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
		{ rel: 'manifest', href: '/site.webmanifest' },
		{ rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
		{ rel: 'shortcut icon', href: '/favicon.ico' },
	],
})

useSeoMeta({
	title: 'Kuchnia Vikinga - reklamacje',
	description: 'Wygodnie zgłoś reklamację za pomocą formularza',
})

defineOgImageStatic({
	component: 'MyOgImage',
	title: 'Kuchnia Vikinga',
})
</script>

<style lang="scss" scoped>
.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	overflow: auto;
}
</style>
