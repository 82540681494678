<template>
	<div class="component__title">
		<div class="component__title-text">
			{{ normalText }}<span class="component__title-text--primary"> {{ primaryText }}</span>
		</div>
		<!-- <span v-if="secondLine"><br />{{ secondLine }}</span> <span class="component__title--primary"> {{ primaryText }}</span> -->
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'

const currentState = inject('currentState') as Ref<INode>
const primaryText = ref()
const normalText = ref()

watch(
	() => currentState.value,
	() => {
		const splittedTitle = currentState.value.header.text.split(' ')
		const wordsAmount = currentState.value.header.highlightedWordsAmount
		primaryText.value = splittedTitle.slice(wordsAmount ? -wordsAmount : -1).join(' ')
		normalText.value = splittedTitle.slice(0, splittedTitle.length - (wordsAmount ?? 1)).join(' ') + ' '
	},
	{ immediate: true }
)
</script>

<style lang="scss">
@import './TitleComponent.scss';
</style>
