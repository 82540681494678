<template>
	<div class="meal-picker">
		<div v-if="fetchedVikingMeals.length > 0" class="meal-picker__container">
			<GlobalTitleComponent></GlobalTitleComponent>
			<div class="meal-picker__meals-container">
				<GlobalFoodComponent
					v-for="meal in fetchedVikingMeals"
					:key="meal.name"
					:food="meal"
					:selected="selectedMeals ? selectedMeals.includes(meal.name) : false"
					:multiple-options="true"
					@click="selectMeal(meal.name)"></GlobalFoodComponent>
			</div>
			<div class="meal-picker__spacer">
				<v-divider></v-divider>
				<div class="meal-picker__spacer-text">wybierz dodatek</div>
				<v-divider></v-divider>
			</div>
			<div class="meal-picker__additional-choice" :class="isAdditionalMealCardOpen && 'meal-picker__additional-choice--selected'">
				<div
					class="meal-picker__additional-choice__header"
					:class="isAdditionalMealCardOpen && 'meal-picker__additional-choice__header--selected'"
					@click="openAdditionalMealCard">
					<div
						class="meal-picker__additional-choice__check"
						:class="isAdditionalMealCardOpen && 'meal-picker__additional-choice__check--selected'">
						<v-icon class="meal-picker__additional-choice__check-icon" icon="mdi-check-bold"></v-icon>
					</div>
					<div class="meal-picker__additional-choice__title">Dodatek / dodatkowy posiłek</div>
				</div>
				<div v-if="isAdditionalMealCardOpen" class="meal-picker__add-input">
					<v-form ref="additionalMealForm">
						<v-combobox
							ref="comboboxInput"
							v-model="selectedAdditions"
							single-line
							:rules="rules"
							label="Wpisz nazwę dodatku"
							chips
							hide-details="auto"
							closable-chips
							variant="underlined"
							multiple>
							<template #chip="data: any">
								<v-chip
									close-icon="mdi-close"
									size="large"
									class="meal-picker__chip mr-2"
									closable
									variant="outlined"
									@click:close="removeChip(data)"></v-chip>
							</template>
						</v-combobox>
						<!-- <v-autocomplete
							ref="autocompleteInput"
							v-model="selectedAdditions"
							v-model:search="search"
							single-line
							:rules="rules"
							label="Wpisz nazwę dodatku"
							chips
							hide-details="auto"
							closable-chips
							hide-selected
							variant="underlined"
							multiple
							@keyup.enter="addNewChip">
							<template #chip="data: any">
								<v-chip
									close-icon="mdi-close"
									size="large"
									class="meal-picker__chip mr-2"
									closable
									variant="outlined"
									@click:close="removeChip(data)"></v-chip>
							</template>
							<template #no-data>
								<v-list-item @click="addNewChip">
									<v-list-item-title> Dodatek: {{ search }} </v-list-item-title>
								</v-list-item>
							</template>
							<template #append-inner v-if="false">
								<div class="test"></div>
							</template>
						</v-autocomplete> -->
					</v-form>
				</div>
			</div>
			<div class="meal-picker__navigation">
				<div class="meal-picker__navigation-buttons">
					<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
					<ButtonPrimary :text="'Dalej'" :disabled="!isFormValid" @click="getNextStep"></ButtonPrimary>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Value, getValue, fetchAndActivate } from 'firebase/remote-config'
import { IFood } from '~/components/Global/FoodComponent/FoodComponent.vue'
import { adjacencyList } from '~/data/adjacencyListGraph'
import { IMealPickerOptions, INode, NodeHistoryValue } from '~/types/nodeTypes'
import { NodeKeys } from '~/data/keys'

const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, checkRequirementsForNodes, getLastValueFromHistory } = useNavigationService()
const { validateForm } = useFormValidation()
const { getPreviousStep } = useNavigationService()

const additionalMealForm = ref()
const fetchedVikingMeals = ref<IFood[]>([])
const selectedMeals = ref<string[]>([])
const selectedAdditions = ref<string[]>([])
const isAdditionalMealCardOpen = ref<boolean>(false)
// const search = ref()
// const autocompleteInput = ref()
const comboboxInput = ref()
const router = useRouter()
// const addNewChip = async () => {
// 	selectedAdditions.value.push(search.value)
// 	search.value = null
// 	// autocompleteInput.value.blur()
// 	// autocompleteInput.value.focus()
// 	isFormValid.value = await validateComponent()
// }

// remote config
const remoteConfig = getConfig()
fetchAndActivate(remoteConfig)
	.then(() => {
		const val: Value = getValue(remoteConfig, 'vikingMeals')
		fetchedVikingMeals.value = JSON.parse(val.asString())
	})
	.catch((err) => {
		console.log(err)
	})

const selectMeal = async (meal: string) => {
	const index = selectedMeals.value?.indexOf(meal)
	if (index !== -1) {
		selectedMeals.value.splice(index, 1)
	} else {
		const all = 'Cała paczka'
		if (meal === all) {
			selectedMeals.value = [all]
		} else {
			const index = selectedMeals.value?.indexOf(all)
			if (index > -1) selectedMeals.value.splice(index, 1)
			selectedMeals.value?.push(meal)
		}
	}
	isFormValid.value = await validateComponent()
}

const openAdditionalMealCard = async () => {
	isAdditionalMealCardOpen.value = !isAdditionalMealCardOpen.value
	isFormValid.value = await validateComponent()

	if (isAdditionalMealCardOpen.value) {
		comboboxInput.value.focus()
		comboboxInput.value.scrollIntoView({ behavior: 'smooth' })
	}
}
const removeChip = async (meal: any) => {
	const index = selectedAdditions.value?.indexOf(meal.item.value)
	index !== -1 && selectedAdditions.value.splice(index, 1)
	isFormValid.value = await validateComponent()
}

watch(
	() => selectedAdditions.value,
	async () => {
		isFormValid.value = await validateComponent()
	}
)

// validation
const isFormValid = ref<boolean>(false)
const rules = ref([
	(value: string[]) => {
		if (value && value.length > 0) return true
		return 'Musisz dodać co najmniej jeden dodatek'
	},
])
const validateComponent = async () => {
	if (selectedMeals.value.length === 0) {
		if (isAdditionalMealCardOpen.value) {
			await validateForm(additionalMealForm.value)
			return selectedAdditions.value.length > 0
		} else {
			return false
		}
	} else
		return (
			(!isAdditionalMealCardOpen.value && selectedAdditions.value.length === 0) ||
			(isAdditionalMealCardOpen.value && selectedAdditions.value.length > 0)
		)
}

const getNextStep = async () => {
	if (!(await validateComponent())) return
	if (!currentState.value?.key || !selectedMeals.value) return
	const availableNodeKeys: NodeKeys[] = adjacencyList[currentState.value?.key]
	const nodeKey = checkRequirementsForNodes(availableNodeKeys)
	const historyValue: NodeHistoryValue = {
		meals: selectedMeals.value,
		additions: isAdditionalMealCardOpen.value ? selectedAdditions.value : [],
	}
	if (nodeKey.length > 0) changeSiteState(nodeKey[0], historyValue)
	router.push({ hash: `#${currentState.value.key}` })
}

onMounted(async () => {
	const result = getLastValueFromHistory()
	if (result) {
		selectedMeals.value = (result as IMealPickerOptions).meals
		selectedAdditions.value = (result as IMealPickerOptions).additions
		isAdditionalMealCardOpen.value = (result as IMealPickerOptions).additions.length > 0
	}
	isFormValid.value = await validateComponent()
})
</script>

<style lang="scss">
@import './MealPickerComponent.scss';
</style>
