import { NodeKeys } from './keys'

export const adjacencyList = {
	[NodeKeys.MODULE]: [NodeKeys.MEAL_PROBLEM, NodeKeys.DAMAGED_PACKAGE, NodeKeys.DELIVERY_PROBLEM],
	[NodeKeys.MEAL_PROBLEM]: [NodeKeys.EMAIL_MEAL],
	[NodeKeys.DELIVERY_PROBLEM]: [NodeKeys.EMAIL_DELIVERY],
	[NodeKeys.DAMAGED_PACKAGE]: [NodeKeys.EMAIL_PACKAGE],
	[NodeKeys.EMAIL_MEAL]: [
		NodeKeys.WRONG_PACKAGE_CONTENT,
		NodeKeys.EMPTY_PACKAGE,
		NodeKeys.BAD_MEAL_QUALITY,
		NodeKeys.FOREIGN_BODY,
		// NodeKeys.MEAL_POISONING,
		NodeKeys.MEAL_LACKS_INGREDIENTS,
		NodeKeys.MEAL_PROBLEM_OTHER,
	],
	[NodeKeys.EMAIL_DELIVERY]: [NodeKeys.PACKAGE_MISSING, NodeKeys.DELAYED_DELIVERY, NodeKeys.DELIVERY_COMMENT, NodeKeys.DELIVERY_PROBLEM_OTHER],
	[NodeKeys.EMAIL_PACKAGE]: [NodeKeys.CRACKED_PACKAGE, NodeKeys.UNSEALED_PACKAGE, NodeKeys.TORN_PACKAGE, NodeKeys.DAMAGED_PACKAGE_OTHER],
	[NodeKeys.CRACKED_PACKAGE]: [NodeKeys.PICK_DIET],
	[NodeKeys.UNSEALED_PACKAGE]: [NodeKeys.PICK_DIET],
	[NodeKeys.TORN_PACKAGE]: [NodeKeys.PICK_DIET],
	[NodeKeys.DAMAGED_PACKAGE_OTHER]: [NodeKeys.PICK_DIET],
	[NodeKeys.DELIVERY_DATE]: [NodeKeys.PICK_DIET],
	// [NodeKeys.PROBLEM_DATE]: [NodeKeys.PICK_DIET],
	[NodeKeys.PICK_MEALS]: [NodeKeys.REQUIRED_PHOTO, NodeKeys.OPTIONAL_PHOTO, NodeKeys.DESCRIPTION],
	[NodeKeys.PICK_DIET]: [NodeKeys.PICK_MEALS, NodeKeys.OPTIONAL_PHOTO, NodeKeys.DESCRIPTION],
	[NodeKeys.REQUIRED_PHOTO]: [NodeKeys.DESCRIPTION],
	[NodeKeys.OPTIONAL_PHOTO]: [NodeKeys.DESCRIPTION],
	[NodeKeys.FOREIGN_BODY]: [NodeKeys.PICK_DIET],
	// [NodeKeys.MEAL_POISONING]: [NodeKeys.PICK_DIET],
	[NodeKeys.DESCRIPTION]: [NodeKeys.SUMMARY],
	[NodeKeys.COMPLAINT_METHOD]: [NodeKeys.SUMMARY],
	[NodeKeys.MEAL_PROBLEM_OTHER]: [NodeKeys.PICK_DIET],
	[NodeKeys.BAD_MEAL_QUALITY]: [
		NodeKeys.SWOLLEN_FOIL,
		NodeKeys.MOLD_IN_MEAL,
		NodeKeys.SMELL_OF_MEAL,
		NodeKeys.BAD_TASTE_OF_MEAL,
		NodeKeys.BAD_MEAL_QUALITY_OTHER,
	],
	[NodeKeys.BAD_MEAL_QUALITY_OTHER]: [NodeKeys.PICK_DIET],
	[NodeKeys.SWOLLEN_FOIL]: [NodeKeys.PICK_DIET],
	[NodeKeys.MOLD_IN_MEAL]: [NodeKeys.PICK_DIET],
	[NodeKeys.SMELL_OF_MEAL]: [NodeKeys.PICK_DIET],
	[NodeKeys.BAD_TASTE_OF_MEAL]: [NodeKeys.PICK_DIET],
	[NodeKeys.EMPTY_PACKAGE]: [NodeKeys.IS_STICKER_ON_PACKAGE],
	[NodeKeys.IS_STICKER_ON_PACKAGE]: [NodeKeys.DELIVERY_DATE],
	[NodeKeys.WRONG_PACKAGE_CONTENT]: [
		NodeKeys.DIFFERENT_MEAL_THAN_ORDERED,
		NodeKeys.MEAL_INCOMPATIBLE_WITH_DIET,
		NodeKeys.DIFFERENT_CALORIES_THAN_ORDERED,
		NodeKeys.WRONG_PACKAGE_CONTENT_OTHER,
	],
	[NodeKeys.WRONG_PACKAGE_CONTENT_OTHER]: [NodeKeys.PICK_DIET],
	[NodeKeys.DIFFERENT_MEAL_THAN_ORDERED]: [NodeKeys.PICK_DIET],
	[NodeKeys.MEAL_INCOMPATIBLE_WITH_DIET]: [NodeKeys.PICK_DIET],
	[NodeKeys.DIFFERENT_CALORIES_THAN_ORDERED]: [NodeKeys.PICK_DIET],
	[NodeKeys.MEAL_LACKS_INGREDIENTS]: [NodeKeys.PICK_DIET],
	[NodeKeys.PACKAGE_MISSING]: [NodeKeys.PICK_DIET_MULTIPLE],
	[NodeKeys.PICK_DIET_MULTIPLE]: [NodeKeys.DESCRIPTION],
	[NodeKeys.DELAYED_DELIVERY]: [NodeKeys.PICK_DIET],
	[NodeKeys.DELIVERY_COMMENT]: [NodeKeys.PICK_DIET],
	[NodeKeys.DELIVERY_PROBLEM_OTHER]: [NodeKeys.PICK_DIET],
	[NodeKeys.SUMMARY]: [NodeKeys.SUCCESS],
	[NodeKeys.SUCCESS]: [],
}
