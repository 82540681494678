<template>
	<div class="description-component">
		<div class="description-component__container">
			<GlobalTitleComponent></GlobalTitleComponent>
			<div class="description-component__label">
				Wpisz tutaj dodatkowe informacje, które według Ciebie pomogą nam lepiej zrozumieć zgłoszenie.
			</div>
			<v-textarea
				v-model="description"
				class="description-component__input"
				rounded
				variant="solo"
				:rules="rules"
				no-resize
				counter
				persistent-counter
				auto-grow
				rows="7"
				placeholder="Opisz tutaj swój problem zgłoszenia"
				@update:model-value="validateDescription">
				<template #counter="data"
					><div class="description-component__counter">
						<v-counter active :max="maxLength" :value="data.value"></v-counter>
					</div>
				</template>
			</v-textarea>
			<div class="description-component__navigation">
				<div class="description-component__navigation-buttons">
					<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
					<ButtonPrimary :text="'Dalej'" :disabled="!isFormValid" @click="getNextStep"></ButtonPrimary>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { adjacencyList } from '~/data/adjacencyListGraph'
import { INode } from '~/types/nodeTypes'

const { changeSiteState, getLastValueFromHistory, getPreviousStep } = useNavigationService()
const currentState = inject('currentState') as Ref<INode>
const description = ref<string>('')
const maxLength = 1500
const isFormValid = ref<boolean>(true)
const router = useRouter()
const getNextStep = () => {
	if (!currentState.value?.key) return
	if (!isFormValid.value) return
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], description.value)
	router.push({ hash: `#${currentState.value.key}` })
}

const validateDescription = () => {
	isFormValid.value = description.value.length <= maxLength
}

const rules = ref([
	(value: string) => {
		if (value && value.length <= maxLength) return true
		if (!value) return true
		return `Maksymalna ilość znaków wynosi ${maxLength}`
	},
])

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) description.value = result as string
})
</script>

<style lang="scss">
@import './DescriptionComponent.scss';
</style>
