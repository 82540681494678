<template>
	<div class="photos-upload-container">
		<div v-if="errorModal" id="error-modal" class="error-modal">
			<div ref="errorModalRef" class="error-modal__container">
				<div class="error-modal__text">
					<div class="error-modal__title">Nie udało się dodać zdjęcia</div>
					<div class="error-modal__desc">
						{{ imageError }}
					</div>
				</div>
				<div class="error-modal__buttons">
					<ButtonPrimary class="error-modal__primary-button" :text="'Dodaj inne zdjęcie'" @click="errorModal = !errorModal"></ButtonPrimary>
					<div class="error-modal__secondary-button" @click="errorModal = !errorModal">OK</div>
				</div>
			</div>
		</div>
		<div class="photos-upload-container__content">
			<div class="photos-upload-container__title-and-desc">
				<GlobalTitleComponent class="photos-upload-container__title" />
				<div class="photos-upload-container__description">
					Pamiętaj, aby zdjecia w widoczny sposób ukazały zgłaszany przez Ciebie problem.
				</div>
			</div>
			<div class="photos-upload-container__photos-container">
				<div class="photos-upload-container__photos">
					<div v-for="(image, index) in images" :key="index">
						<div
							class="photos-upload-container__input"
							:class="{ 'photos-upload-container__input--filled': images[index].imagePreview !== '' }"
							@click="images[index].imagePreview !== '' && togglePreviewOverlay(index)">
							<v-img :src="image.imagePreview" class="photos-upload-container__img-container"> </v-img>
							<v-icon
								v-if="images[index].imagePreview !== '' && !image.loader"
								icon="mdi-magnify"
								class="photos-upload-container__preview-icon"></v-icon>
							<v-icon
								v-if="images[index].imagePreview === '' && !image.loader"
								icon="mdi-image-plus-outline"
								class="photos-upload-container__image-icon"
								color="black"></v-icon>
							<input
								type="file"
								class="photos-upload-container__image-input"
								multiple
								placeholder="Hidden Text"
								accept="image/png, image/jpeg"
								:title="images[index].imagePreview === '' ? 'Nie wybrano pliku' : 'Usuń zdjęcie'"
								:style="images[index].imagePreview !== '' ? 'display: none' : 'display: block'"
								@change="(e) => onFileChange(e as HTMLInputEvent)" />
							<div class="photos-upload-container__delete-icon-container">
								<v-icon
									v-if="images[index].imagePreview !== '' && !image.loader"
									icon="mdi-close-circle"
									class="photos-upload-container__delete-icon"
									@click="(e) => clearImage(e, index)"></v-icon>
							</div>
							<v-progress-circular v-if="image.loader" indeterminate class="photos-upload-container__image-icon"></v-progress-circular>
						</div>
					</div>
					<!-- PREVIEW OVERLAY -->
					<GlobalPhotoPreview
						id="image"
						:images="images"
						:preview-overlay="previewOverlay"
						:previewed-image-index="previewedImageIndex"
						@togglePreviewOverlay="togglePreviewOverlay" />
				</div>
				<div class="photos-upload-container__photos-info">
					<span v-if="currentState.type === StepType.OPTIONAL_PHOTO">*Dodanie zdjęć jest opcjonalne</span>
					<span v-else>Dodaj od 1 do 8 zdjęć.</span>
				</div>
			</div>
			<div class="photos-upload-container__buttons">
				<ButtonSecondary :text="'Poprzedni krok'" @click="getPreviousStep"></ButtonSecondary>
				<ButtonPrimary :class="isDisabled && 'btn--disabled'" :text="'Dalej'" @click="getNextStep(images)"></ButtonPrimary>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Notyf } from 'notyf'
import Compressor from 'compressorjs'
import { adjacencyList } from '~/data/adjacencyListGraph'
import { INode } from '~/types/nodeTypes'
import 'notyf/notyf.min.css'
const notyf = new Notyf({
	position: {
		x: 'center',
		y: 'top',
	},
	duration: 2000,
})

const { changeSiteState, getLastValueFromHistory, getPreviousStep } = useNavigationService()
const currentState = inject('currentState') as Ref<INode>

export interface IImage {
	image: File
	imagePreview: string
	loader?: boolean
}

export interface IAttachment {
	name?: string
	data?: string
	content?: string
	filename?: string
}

interface HTMLInputEvent extends Event {
	target: HTMLInputElement & EventTarget
}

const images = ref<IImage[]>([
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
	{
		image: {} as File,
		imagePreview: '',
		loader: false,
	},
])

const imageError = ref('')
const buttonLoader = ref(false)
const previewOverlay = ref(false)
const previewedImageIndex = ref(0)

const isDisabled = ref(currentState.value.type === StepType.REQUIRED_PHOTO)

const errorModal = ref(false)
const errorModalRef = ref(null)

const router = useRouter()

watch(
	() => router.currentRoute.value.hash,
	() => {
		if (router.currentRoute.value.hash === '#error-modal') {
			errorModal.value = true
		} else if (router.currentRoute.value.hash === '#image') {
			previewOverlay.value = true
		} else {
			errorModal.value = false
			previewOverlay.value = false
		}
	}
)

watch(
	() => errorModal.value,
	() => {
		if (errorModal.value) {
			router.push({ hash: '#error-modal' })
		} else {
			router.push({ hash: '' })
		}
	}
)

watch(
	() => previewOverlay.value,
	() => {
		if (previewOverlay.value) {
			router.push({ hash: '#image' })
		} else {
			router.replace({ hash: '' })
		}
	}
)

onClickOutside(errorModalRef, () => {
	errorModal.value = false
})

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) {
		images.value = result as IImage[]
		if (currentState.value.type === StepType.REQUIRED_PHOTO) isDisabled.value = false
	}
})

const getNextStep = (select: IImage[]) => {
	if (isDisabled.value) {
		notyf.error('Przesłanie zdjęć jest wymagane dla wybranego typu problemu')
		return
	}
	if (!currentState.value?.key) return
	if (areImagesEmpty.value && currentState.value?.key === NodeKeys.REQUIRED_PHOTO) {
		imageError.value = 'Please add at least one image'
		buttonLoader.value = false
		return
	}
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], select)
	router.push({ hash: `#${currentState.value.key}` })
}

const togglePreviewOverlay = (imageIndex: number) => {
	previewedImageIndex.value = imageIndex
	previewOverlay.value = !previewOverlay.value
}

const onFileChange = async (e?: HTMLInputEvent, optimize = true) => {
	if (!e?.target.files) return

	imageError.value = ''
	const emptyImages = images.value.filter((image) => !image.imagePreview).length
	const filesLength = e.target.files.length
	let files: File[] = []

	// if there are more files than empty spots, slice the array to fit the empty spots
	if (filesLength >= emptyImages) {
		files = Array.from(e.target.files).slice(0, emptyImages) as File[]
	} else {
		files = Array.from(e.target.files) as File[]
	}

	for (let i = 0; i < files.length; i++) {
		// check if image size is less than 20MB
		if (files[i].size > 5242880) {
			// imageError.value = 'File ' + files[i].name + ' is too large (max 20MB)'
			imageError.value = 'Zdjęcie, które próbujesz dodać przekracza maksymalny rozmiar 5MB. Zmniejsz rozmiar zdjęcia lub wybierz inne.'
			errorModal.value = true
			return
		}

		// check if image already exists in images array
		const imageExists = images.value.find((image) => image.image.name === files[i].name)
		if (imageExists) {
			imageError.value = 'Zdjęcie o nazwie ' + shortenFileName(files[i].name) + ' zostało już dodane.'
			errorModal.value = true
		}

		// // check if image format is correct
		else if (!files[i].type.match('image.*')) {
			imageError.value = 'Plik ' + shortenFileName(files[i].name) + ' ma nieprawidłowy format. '
			errorModal.value = true
		}
		// add image to images array
		else {
			const imageIndex = images.value.findIndex((image) => !image.imagePreview)
			const image = images.value[imageIndex]
			if (image) {
				// if image size is between 1MB and 20MB, optimize it
				if (files[i].size > 1048576 && files[i].size < 20971520 && optimize) {
					image.loader = true
					let optimizedImage = null
					try {
						optimizedImage = (await optimizeImage(files[i])) as Blob
					} catch (err) {
						notyf.error('Nie udało się przetworzyć zdjęcia. Spróbuj ponownie lub wybierz inne zdjęcie.')
					}
					image.loader = false
					if (!optimizedImage) return

					image.image = new File([optimizedImage], files[i].name)
					image.imagePreview = URL.createObjectURL(optimizedImage as File)
				} else {
					image.image = files[i]
					image.imagePreview = URL.createObjectURL(files[i])
				}
			}
			if (currentState.value.type === StepType.REQUIRED_PHOTO) isDisabled.value = false
			// getImageResolution(image.image as File) // To check image resolution
		}
	}
	resetValuesFromInputs()
}

const optimizeImage = (image: File) => {
	return new Promise((resolve, reject) => {
		// eslint-disable-next-line no-new
		new Compressor(image, {
			quality: 0.7,
			maxWidth: 1280,
			maxHeight: 1280,
			success(result) {
				resolve(result)
			},
			error(err) {
				reject(err)
			},
		})
	})
}

const clearImage = (event: Event, index: number) => {
	event.stopPropagation()
	images.value[index].imagePreview = ''
	images.value[index].image = {} as File
	movePhotos()
}

const resetValuesFromInputs = () => {
	const inputs = document.querySelectorAll('.photos-upload-container__image-input') as NodeListOf<HTMLInputElement>
	inputs.forEach((input) => {
		input.value = ''
	})
}

const areImagesEmpty = computed(() => {
	return images.value.every((image) => image.imagePreview === '')
})

watch(
	() => images.value,
	() => {
		movePhotos()
	}
)

const movePhotos = () => {
	// if images are empty, reset errors
	if (areImagesEmpty.value) {
		imageError.value = ''
		currentState.value.type === StepType.REQUIRED_PHOTO ? (isDisabled.value = true) : (isDisabled.value = false)
		return
	}
	// move empty images to the end of the array
	for (let i = 0; i < images.value.length - 1; i++) {
		if (images.value[i].imagePreview === '' && images.value[i + 1].imagePreview !== '') {
			images.value[i].image = images.value[i + 1].image
			images.value[i].imagePreview = images.value[i + 1].imagePreview
			images.value[i + 1].image = {} as File
			images.value[i + 1].imagePreview = ''
		}
	}
}

watch(
	() => previewOverlay.value,
	() => {
		if (previewOverlay.value === true) {
			window.addEventListener('keyup', (e) => {
				if (e.key === 'Escape') {
					previewOverlay.value = false
				}
			})
		}
	}
)

const shortenFileName = (name: string) => {
	if (name.length <= 20) return name
	const ext = name.slice(name.lastIndexOf('.'))
	const fileName = name.slice(0, name.lastIndexOf('.'))
	const shortenedName = fileName.slice(0, 15).concat('[...]')
	return shortenedName + ext
}
</script>

<style lang="scss">
@import './PhotoUploaderComponent.scss';
</style>
