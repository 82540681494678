<template>
	<div class="summary-right-section">
		<div class="summary-right-section__content-box summary-right-section__content-box--border-bt">
			<div class="summary-right-section__title">{{ diet.includes(', ') ? 'Diety' : 'Dieta' }}</div>
			<div class="summary-right-section__diet-name">{{ diet }}</div>
		</div>
		<div v-if="meals" class="summary-right-section__content-box">
			<div class="summary-right-section__title">Posiłki, których dotyczy problem</div>
			<div class="summary-right-section__pills">
				<div v-for="meal in meals" :key="meal" class="summary-right-section__pill">{{ meal }}</div>
			</div>
		</div>
		<div v-if="images && images[0] && images[0].imagePreview" class="summary-right-section__content-box">
			<div class="summary-right-section__title">Zdjęcia</div>
			<div class="summary-right-section__photos">
				<v-img
					v-for="(image, index) in filteredImages"
					:key="index"
					:src="image.imagePreview"
					class="summary-right-section__img"
					@click="togglePreviewOverlay(index)"></v-img>
			</div>
		</div>
		<div v-if="description" class="summary-right-section__content-box">
			<div class="summary-right-section__title">Opis</div>
			<div class="summary-right-section__problem-desc">
				{{ description }}
			</div>
		</div>
		<GlobalPhotoPreview
			:images="images"
			:preview-overlay="previewOverlay"
			:previewed-image-index="previewedImageIndex"
			@togglePreviewOverlay="togglePreviewOverlay" />
	</div>
</template>

<script setup lang="ts">
import { IImage } from '../../PhotoUploaderComponent/PhotoUploaderComponent.vue'
import { IMealPickerOptions } from '~/types/nodeTypes'

const { getPhotosFromHistory, getMealsFromHistory, getDietFromHistory, getDescriptionFromHistory } = useNavigationService()
const images = ref<IImage[]>([])
const meals = ref()
const diet = ref('')
const description = ref('')
const previewOverlay = ref(false)
const previewedImageIndex = ref(0)
const router = useRouter()

watch(
	() => previewOverlay.value,
	() => {
		if (previewOverlay.value) {
			router.push({ hash: '#image' })
		} else {
			router.replace({ hash: '' })
		}
	}
)

watch(
	() => router.currentRoute.value.hash,
	() => {
		if (router.currentRoute.value.hash === '#image') {
			previewOverlay.value = true
		} else {
			previewOverlay.value = false
		}
	}
)

onMounted(() => {
	images.value = getPhotosFromHistory() as IImage[]
	const allMeals = getMealsFromHistory() as IMealPickerOptions
	if (allMeals) meals.value = [...allMeals.meals, ...allMeals.additions]
	diet.value = getDietFromHistory() as string
	description.value = getDescriptionFromHistory() as string
})

const togglePreviewOverlay = (imageIndex: number) => {
	previewedImageIndex.value = imageIndex
	previewOverlay.value = !previewOverlay.value
}

const filteredImages = computed(() => {
	if (images.value) return images.value.filter((image) => image.imagePreview !== '')
})
</script>

<style lang="scss">
@import './RightSectionPartial.scss';
</style>
