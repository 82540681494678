<template>
	<div>
		<CalendarComponent @selectedDate="updateSelectedDate" />
		<v-btn :disabled="!dateProblem" @click="getNextStep(dateProblem)">Next</v-btn>
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'

const currentState = inject('currentState') as Ref<INode>
const { changeSiteState, getLastValueFromHistory } = useNavigationService()
const dateProblem = ref()

const getNextStep = (select: string) => {
	if (!currentState.value?.key) return
	const nodeKey = adjacencyList[currentState.value?.key]
	changeSiteState(nodeKey[0], select)
}

onMounted(() => {
	const result = getLastValueFromHistory()
	if (result) dateProblem.value = result as string
})

function updateSelectedDate(value: Date) {
	dateProblem.value = value
}
</script>

<style lang="scss">
@import './DateProblemComponent.scss';
</style>
