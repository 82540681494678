import { initializeApp } from 'firebase/app'
import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
	apiKey: 'AIzaSyBBqljY6Rovkk5SQWss8wIjpTXP5H8yVUI',
	authDomain: 'kuchnia-vikinga---reklamacje.firebaseapp.com',
	projectId: 'kuchnia-vikinga---reklamacje',
	storageBucket: 'kuchnia-vikinga---reklamacje.appspot.com',
	messagingSenderId: '1071080382442',
	appId: '1:1071080382442:web:6dc2228234d7b54decdc93',
}

export const app = initializeApp(firebaseConfig)

let remoteConfig: RemoteConfig

export const getConfig = () => {
	return remoteConfig
}

if (typeof window !== 'undefined') {
	remoteConfig = getRemoteConfig(app)
	// remoteConfig.settings.minimumFetchIntervalMillis = 1000
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000 // 1hr
}
