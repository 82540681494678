<template>
	<v-overlay
		v-if="previewOverlay"
		class="photo-preview-component align-center justify-center"
		:model-value="previewOverlay"
		@click:outside="togglePreviewOverlay(0)">
		<img :src="images?.[previewedImageIndex].imagePreview" class="photo-preview-component__image" />
		<div class="photo-preview-component__icon-container" @click="togglePreviewOverlay(0)">
			<v-icon class="photo-preview-component__icon">mdi-close</v-icon>
		</div>
	</v-overlay>
</template>

<script setup lang="ts">
import { IImage } from '../../Step/PhotoUploaderComponent/PhotoUploaderComponent.vue'

defineProps<{
	images?: IImage[]
	previewOverlay: boolean
	previewedImageIndex: number
}>()

const emit = defineEmits(['togglePreviewOverlay'])

const togglePreviewOverlay = (index: number) => {
	emit('togglePreviewOverlay', index)
}
</script>

<style lang="scss">
@import './PhotoPreview.scss';
</style>
