<template>
	<div class="contact-section">
		<v-divider></v-divider>
		<div class="contact-section__title">
			<span>Potrzebujesz pomocy z innym problemem? </span
			><span class="contact-section__title--highlighted" @click="scrollMeTo">Napisz lub zadzwoń</span>
		</div>
		<div ref="section" class="contact-section__options">
			<div class="contact-section__telephone">
				<nuxt-icon class="contact-section__icon" name="call" filled />
				<div class="contact-section__text">
					<div class="contact-section__telephone-number--highlighted">+48</div>
					<a :href="`tel:${mobile}`" class="contact-section__telephone-number">
						{{ mobile }}
					</a>
					<div class="contact-section__hours">7:00 - 22:00</div>
				</div>
			</div>
			<div class="contact-section__email">
				<nuxt-icon class="contact-section__icon" name="sms" filled />
				<a :href="`mailto:${email}`" class="contact-section__text contact-section__text--light">{{ email }}</a>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const mobile = ref('22 230 24 00')
const email = ref('biuro@kuchniavikinga.pl')
const section = ref()
const scrollMeTo = () => {
	section.value.scrollIntoView({ behavior: 'smooth' })
}
</script>

<style lang="scss">
@import './ContactPartial.scss';
</style>
